import React, {useEffect, useState} from 'react';
import {Col, Row, Spacer, View} from '@amzn/stencil-react-components/layout';
import {useBreakpoints} from "@amzn/stencil-react-components/responsive";
import {Text} from '@amzn/stencil-react-components/text';
import { getEnvironmentBasedURL} from './Utils';
import './HomePage.css';
import {UNAUTHORISED_TIMEOUT} from "src/constants";

export const HomePageUnauthorised = () => {
    const {matches} = useBreakpoints();
    const isMobile = matches.s || matches.m;
    const stage: string = "alpha";
    document.title = "My HR Lite Welcome Page";
    let [timer, setTimer] = useState(UNAUTHORISED_TIMEOUT)


    useEffect(() => {
        setTimeout(() => {
            if (timer <= 0) {
                redirect();
            } else {
                setTimer(timer - 1);
            }
        }, 1000);
    }, [timer]);

    const redirect = () => {
        window.location.href = "/";
    }

    return (
        <View>
            <View className='header'>
                <img
                    className='header-image'
                    role="presentation"
                    height="44px"
                    width="92px"
                    alt={"MyHR Logo"}
                    src={getEnvironmentBasedURL('MyHRLogo')}
                />
            </View>

            <Col>
                <Col margin={'106px 506px 0 463px'}>
                    <img className='image-left'
                         role="presentation"
                         alt={"Image"}
                         height={"329px"}
                         width={"471px"}
                         style={{alignSelf: "center"}}
                         src={getEnvironmentBasedURL('Unauthorised')}
                    /></Col>
                    <Spacer height="33px"/>
                    <Col margin={'0 416px 0 416px'} style={{alignSelf: "center"}}>
                        <Text className={"unauthorised-text-1-l"}>
                            You’re not authorized to access MyHR live support on this device
                        </Text>
                    </Col>
                    <Spacer height="33px"/>
                    <Col>
                        <Text className={"unauthorised-text-2-l"}>
                            To connect with HR support, please use&nbsp; MyHR via the AtoZ app.
                        </Text>
                    </Col>
                    <Spacer height="32px"/>
                    <Col >
                        <Row width ={"503px"} height={"32px"} style={{alignSelf: "center", justifyContent: "center"}} margin={'0 469px 267px 468px'}>
                            <Text className={"unauthorised-text-3-l"}>
                                Automatic log out in:
                            </Text>  <Text className={"unauthorised-text-4-l"}> &nbsp;
                            {timer} seconds
                        </Text>
                        </Row>
                    </Col>
                </Col>
        </View>
    );
};
